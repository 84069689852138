import React, { useContext } from "react";

import { graphql } from "gatsby";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { DataContext } from "../../../providers/CookieConsentProvider";
import { Blurred } from "../Blur";
import Columns from "../Columns";
import Container from "../Container";
import Headline from "../Headline";
import Icon from "../icons/Icon";
import JsonLd from "../JsonLd";
import LinkComponent, { Link as ExternalLink, Link } from "../LinkComponent";
import useStore from "../Navigation/ContactForm/store";
import useNewsletterModalStore from "../NewsletterModal/store";
import TrackableContent from "../TrackableContent";
import shapeCornerImage from "./shape-corner.svg";

const red = "#E60028";

const Wrapper = styled(Blurred)`
    position: relative;
    display: flex;
    min-height: 200px;

    .legacyPage & {
        font-family: var(--font-family-vito);
    }
`;

const LeftShapeContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    min-height: 100%;
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;

    svg {
        width: auto;
        height: 100%;
    }
    @media screen and (max-width: 1023px) {
        display: none;
    }
`;

const RedFill = styled.div`
    display: flex;
    flex: 1;
    min-height: 100%;
    background-color: ${red};
    @media screen and (max-width: 1023px) {
        display: none;
    }
`;

export const FooterContainer = styled(Container)`
    flex-grow: 50;
    padding-top: 40px;
    padding-bottom: 40px;
    color: white;
    background-color: ${red};
`;

const headline = css`
    font-weight: 900;
`;

const FooterColumnContent = styled.div`
    font-size: 0.75rem;
`;

const FooterLinkList = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    a {
        color: ${({ theme }) => theme.colors.white.toString()};
        text-decoration: underline;

        :hover {
            color: ${({ theme }) => theme.colors.white.toString()};
        }
    }
`;

const contactLink = css`
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 0.75rem !important;
    font-weight: 900;
`;

const ContentAndFooter = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Content = styled.div`
    flex-grow: 1;
`;

const StyledLink = styled.a`
    color: ${({ theme }) => theme.colors.white.toString()};
    text-decoration: underline;

    :hover {
        color: ${({ theme }) => theme.colors.white.toString()};
    }
`;

const SocialIconWrapper = styled.div`
    display: flex;
    column-gap: 10px;
    ${({ theme }) => theme.breakpoints.upTo.extraSmall.css`
         width: 100%;
         justify-content: space-between;
         ${theme.paddings.containerOuterInner.left}
         ${theme.paddings.containerOuterInner.right}
    `}
`;

const isExternalLink = (link) => link && !link.startsWith("/");
const getHttpsLink = (link) => {
    return link && link.startsWith("www.") ? `https://${link}` : link;
};

const Meta = ({ name, address, url, email, telephone }) => (
    <JsonLd>
        {{
            "@context": "https://schema.org/",
            "@id": `https://www.hoyer.de/impressum#${encodeURIComponent(name)}`,
            "@type": "Corporation",
            name,
            address: {
                "@type": "PostalAddress",
                ...address,
            },
            url,
            email,
            telephone,
        }}
    </JsonLd>
);

const Footer = ({ footer }) => {
    const { setContactFormVisible } = useStore();
    const { openNewsletterModal } = useNewsletterModalStore();

    const cookieContext = useContext(DataContext);
    return (
        <Wrapper eles={["Footer"]}>
            <Meta {...footer.contact.organization} />
            <TrackableContent name="footer">
                <LeftShapeContainer
                    style={{ backgroundImage: `url(${shapeCornerImage})` }}
                />
                <FooterContainer>
                    <Columns>
                        <Columns.Column desktop="4" tablet="4">
                            <Headline css={headline} lookLike="4">
                                {footer.contact.name}
                            </Headline>
                            <FooterColumnContent>
                                <p>{footer.contact.organization.name}</p>
                                <p>
                                    {
                                        footer.contact.organization.address
                                            .streetAddress
                                    }
                                </p>
                                <p>
                                    {
                                        footer.contact.organization.address
                                            .postalCode
                                    }{" "}
                                    {
                                        footer.contact.organization.address
                                            .locality
                                    }
                                </p>
                                <p>
                                    {
                                        footer.contact.organization.address
                                            .country
                                    }
                                </p>
                                <p>
                                    <StyledLink
                                        href={getHttpsLink(
                                            footer.contact.organization.url
                                        )}
                                    >
                                        {footer.contact.organization.url}
                                    </StyledLink>
                                </p>
                                <p>
                                    <StyledLink
                                        href={`mailto:${footer.contact.organization.email}`}
                                    >
                                        {footer.contact.organization.email}
                                    </StyledLink>
                                </p>
                            </FooterColumnContent>
                            <LinkComponent
                                noLink
                                icon="arrow-right"
                                color="white"
                                css={contactLink}
                                link={{ text: footer.contact.formLinkText }}
                                onClick={() => setContactFormVisible(true)}
                            />
                        </Columns.Column>
                        {footer.sections.map((section, i) => (
                            <Columns.Column
                                key={`section_${section.name}`}
                                desktop="2"
                                tablet="4"
                            >
                                <Headline css={headline} lookLike="4">
                                    {section.name}
                                </Headline>

                                <FooterColumnContent>
                                    <FooterLinkList>
                                        {section.items.map((item, i) => {
                                            /* Overwrite the old newsletter page,
                                            which is not working anyway with the modal popup.*/
                                            if (
                                                item.href.includes(
                                                    "news.hoyer.de"
                                                )
                                            ) {
                                                return (
                                                    <a
                                                        key={item.text}
                                                        href={item.href}
                                                        role="button"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            openNewsletterModal();
                                                        }}
                                                    >
                                                        {item.text}
                                                    </a>
                                                );
                                            }

                                            if (
                                                item.href.includes(
                                                    "#cookieConsent"
                                                )
                                            ) {
                                                return (
                                                    <a
                                                        key={item.text}
                                                        role="button"
                                                        href={item.href}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            cookieContext.actions.setOpen(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        {item.text}
                                                    </a>
                                                );
                                            }
                                            return (
                                                <span key={item.text}>
                                                    {!isExternalLink(
                                                        item.href
                                                    ) && (
                                                        <Link to={item.href}>
                                                            {item.text}
                                                        </Link>
                                                    )}
                                                    {isExternalLink(
                                                        item.href
                                                    ) && (
                                                        <ExternalLink
                                                            to={item.href}
                                                            target="_blank"
                                                        >
                                                            {item.text}
                                                        </ExternalLink>
                                                    )}
                                                </span>
                                            );
                                        })}
                                    </FooterLinkList>
                                </FooterColumnContent>
                            </Columns.Column>
                        ))}
                    </Columns>
                    <SocialIconWrapper>
                        {footer.contact.organization.socialMedia.map(
                            (item, index) => (
                                <ExternalLink
                                    key={index}
                                    to={item.href}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Icon
                                        iconImg={item.image.src.publicURL}
                                        altText={item.name}
                                    />
                                </ExternalLink>
                            )
                        )}
                    </SocialIconWrapper>
                </FooterContainer>
            </TrackableContent>
            <RedFill />
        </Wrapper>
    );
};
export default Footer;

export const WithFooter = ({ children, ...props }) => (
    <ContentAndFooter>
        <TrackableContent name="content">
            <Content>{children}</Content>
        </TrackableContent>
        <Footer {...props} />
    </ContentAndFooter>
);

export const query = graphql`
    fragment Footer on FootersYaml {
        contact {
            organization {
                name
                address {
                    streetAddress
                    postalCode
                    locality
                    country
                }
                url
                email
                socialMedia {
                    name
                    href
                    image {
                        src {
                            publicURL
                        }
                    }
                }
                telephone
            }
            name
            formLinkText
        }
        sections {
            name
            items {
                text
                href
            }
        }
    }
`;
